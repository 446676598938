
import { defineComponent } from "vue";
import { JWTPage } from "@/core/types/JWT";
//import { calculateJWT } from "@/core/JWT";

export default defineComponent({

    name: "JWT",
    data: () => {

        let data: JWTPage = {

            jwt: {
                header: {
                    typ: "JWT",
                    alg: "None"
                },

                payload: null
            },

            inputs: {

                headerJSON: '',
                payloadJSON: '',
                secret: '',

                token: '',
            },

            error: {
                header: false,
                payload: false,
                secret: false
            }


        }

        return data
    },
    methods: {
        updateHeader: function () {
            try {
                this.error.header = false
                let _h = JSON.parse(this.inputs.headerJSON)
                if (_h.typ === undefined || _h.alg === undefined) throw new Error('"alg" and "type" keys missing')
            } catch (error) {
                this.error.header = true
                let errElement = this.$refs.headerError as HTMLAnchorElement
                console.log(errElement)
                errElement.textContent = error as string
            }
        },
        updatePayload: function () {

            try {
                this.error.header = false
                this.jwt.header = JSON.parse(this.inputs.headerJSON)
            } catch (error) {
                this.error.header = true
            }
        }
    }


})


